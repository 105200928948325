/* reset & styles borrowed from old doc site. feel free to remove any obsolete part */

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
}
body {
  color: #24292e;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
}
@media only screen and (min-width: 736px) {
  body {
    display: flex;
    flex-direction: column;
  }
}
b,
strong {
  font-weight: 600;
}
em,
i {
  font-style: italic;
}
[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
a:active,
a:hover {
  outline-width: 0;
}
a:not([href]) {
  color: inherit;
  text-decoration: none;
}
p {
  margin-bottom: 1em;
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 1.5em;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 13.6px;
}
ol,
ul {
  margin-bottom: 1em;
  margin-top: 0;
  padding-left: 2em;
}
ol ol,
ul ol {
  list-style-type: lower-roman;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
  margin-top: 0;
}
ol ol ol,
ol ul ol,
ul ol ol,
ul ul ol {
  list-style-type: lower-alpha;
}
li {
  word-wrap: break-all;
}
li > p {
  margin-top: 1em;
}
li + li {
  margin-top: 0.25em;
}
img {
  border-style: none;
  box-sizing: content-box;
  max-width: 100%;
}
img[align='right'] {
  padding-left: 1.25em;
}
img[align='left'] {
  padding-right: 1.25em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  margin-bottom: 16px;
  margin-top: 0;
  overflow: auto;
  width: 100%;
}
table tr {
  background-color: transparent;
  border-top: 1px solid #dfe2e5;
}
table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
table td,
table th {
  border: 1px solid #dfe2e5;
  padding: 6px 13px;
}
table th {
  background-color: inherit;
  font-weight: 600;
}
table td,
table th {
  color: inherit;
}
blockquote {
  color: #6a737d;
  font-size: 16px;
  margin: 0;
  margin-bottom: 16px;
  padding: 0 1em;
}
blockquote > :first-child {
  margin-top: 0;
}
blockquote > :last-child {
  margin-bottom: 0;
}
code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  color: inherit;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 85%;
  margin: 0;
  padding: 3.2px 6.4px;
}
pre {
  margin-bottom: 16px;
}
pre code {
  background-color: transparent;
  border: 0;
  display: inline;
  font-size: 85%;
  line-height: inherit;
  margin: 0;
  max-width: auto;
  overflow: visible;
  padding: 0;
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}
kbd {
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-bottom-color: #c6cbd1;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #c6cbd1;
  color: #444d56;
  display: inline-block;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 68.75%;
  line-height: 10px;
  padding: 3px 5px;
  vertical-align: middle;
}
hr {
  border: 1px solid #dfe2e5;
  box-sizing: content-box;
  margin: 1.5em 0;
  overflow: hidden;
  padding: 0;
}
hr:after,
hr:before {
  content: '';
  display: table;
}
hr:after {
  clear: both;
}
article:after,
article:before {
  content: '';
  display: table;
}
article:after {
  clear: both;
}
article > :first-child {
  margin-top: 0;
}
article > :last-child {
  margin-bottom: 0;
}
article iframe,
article p img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
blockquote {
  background-color: rgba(255, 229, 100, 0.3);
  border-left: 8px solid #ffe564;
  padding: 15px 30px 15px 15px;
}
input[type='search'] {
  -moz-appearance: none;
  -webkit-appearance: none;
}
input::-ms-input-placeholder {
  color: #e5e5e5;
}
input::placeholder {
  color: #e5e5e5;
}
pre {
  position: relative;
}
.wrapper {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 20px;
}
@media only screen and (min-width: 1500px) {
  .wrapper {
    max-width: 1400px;
  }
}
[data-fixed-header-link] {
  align-items: center;
  border: 0;
  color: black;
  display: flex;
  flex-flow: row nowrap;
  height: 34px;
  z-index: 10000;
}
.fixedHeaderContainer {
  background: white;
  color: #000;
  min-height: 50px;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  z-index: 99999;
  transform: translateZ(0);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.fixedHeaderContainer header {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  text-align: left;
}
.fixedHeaderContainer header img {
  height: 100%;
  margin-right: 10px;
}
.fixedHeaderContainer header h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
  text-decoration: underline;
}
.headerTitleWithLogo {
  display: block;
  font-size: 1.25em;
  line-height: 18px;
  margin: 0;
  position: relative;
  z-index: 9999;
}
@media (max-width: 480px) {
  .headerTitleWithLogo {
    display: none !important;
  }
}

.slidingNav {
  bottom: auto;
  box-sizing: border-box;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.slidingNav ul {
  background: #dd5300;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: 50px;
  padding: 0;
  width: 100%;
}
.slidingNav ul li {
  flex: 1 1 auto;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}
[data-fixed-header-link-nav] {
  align-items: center;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-size: 0.9em;
  height: auto;
  height: 50px;
  justify-content: center;
  margin: 0;
  padding: 10px;
  transition: background-color 0.3s;
}
[data-fixed-header-link-nav]:focus,
[data-fixed-header-link-nav]:hover {
  color: #e95801;
}
.navPusher {
  left: 0;
  min-height: 100%;
  position: relative;
  z-index: 99;
  padding-top: 50px;
}

.navPusher:after {
  background: rgba(0, 0, 0, 0.4);
  content: '';
  height: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  width: 0;
}

@media only screen and (min-width: 768px) {
  .slidingNav {
    background: none;
    height: auto;
    position: relative;
    right: auto;
    top: auto;
    width: auto;
  }
  .slidingNav ul {
    background: none;
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
    width: auto;
  }
  [data-fixed-header-link-nav] {
    border: 0;
    display: flex;
    font-size: 16px;
    font-size: 1em;
    font-weight: 300;
    height: 32px;
    line-height: 1.2em;
    margin: 0;
    padding: 0;
    padding: 6px 10px;
  }
  [data-fixed-header-link-nav]:hover {
    color: #e95801;
  }
}
@media only screen and (max-width: 735px) {
  .slidingNav ul {
    overflow-x: auto;
  }
  .slidingNav ul::-webkit-scrollbar {
    display: none;
  }
}
