@font-face {
  font-family: 'Fira Code VF';
  src: url('font.woff2') format('woff2-variations');
  font-weight: 300 700;
  font-style: normal;
}

:root {
  --gutter: 30px;
  --link-color: #007eff;
  --text-color: #333;
  --error-color: #fff0f0;
  --outline-width: 200px;
  --right-panel-width: 1fr;
  --console-height: 1fr;
  --console-min-height: 38px;
  --primary-color: #e95801;
}

h1,
h2,
h3 {
  font-weight: 500;
  line-height: 1;
  margin-top: 0;
}

body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.CodeMirror-lint-tooltip {
  z-index: 10000 !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
  }
}

.type-hover {
  z-index: 50;
  border: 1px solid #c8c8c8;
  background-color: #f3f3f3;
  user-select: text;
  box-sizing: initial;
  animation: fadein 0.1s linear;
  line-height: 1.5em;
  overflow: hidden;
  cursor: default;
  padding: 4px 8px;
  visibility: hidden;
  opacity: 0;
}

.navPusher {
  height: 100%;
}

.CodeMirror span {
  font-family: 'Fira Code VF', sans-serif;
}

#try-wrapper {
  display: grid;
}

.editor {
  border-left: 1px solid #ddd;
}

.sources {
  display: grid;
  grid-template-rows: 1fr auto;
}

.editor.results {
}

.dom {
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.dom iframe {
  width: 100%;
  height: 100%;
}

.console > :first-child {
  border-top: 1px solid #fff;
}

.errors {
  margin: 0;
  margin-top: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.errors.has-errors {
  z-index: 20;
  background-color: var(--error-color);
  border-left: 1px solid #ffd6d6;
  color: #f00;
}

.errors.no-errors {
  opacity: 0.5;
  z-index: -20;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .navigationWrapper {
    height: 34px;
    margin-left: auto;
    position: relative;
  }
}
@media (max-width: 767px) {
  .navPusher {
    padding-top: 50px;
  }
  .navigationWrapper {
    display: none;
  }
  .layout-sizer {
    /*display: none;*/
  }
  .navPusher {
    min-height: calc(100vh - 100px);
  }
  #try-wrapper {
    width: 100vw;
    overflow-x: hidden;
    scroll-snap-type: mandatory;
    grid-template-rows: 54px minmax(0, 1fr) minmax(
        var(--console-min-height),
        var(--console-height)
      );
    border: none;
    z-index: 9999;
  }
  .header-tabs {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
  .sources {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .dom {
    border-top: 1px solid #ddd;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .editor.results {
    border-top: 1px solid #ddd;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .errors {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    margin: 0;
    margin-top: var(--console-min-height);
    overflow: auto;
    padding: 10px;
  }
}
@media (min-width: 700px) {
  .layout-sizer {
    display: block;
  }
  #try-wrapper {
    overflow: hidden;
    grid-template-columns: var(--outline-width) minmax(200px, 2fr) var(
        --right-panel-width
      );
    grid-template-rows: 36px minmax(0, 1fr) minmax(
        var(--console-min-height),
        var(--console-height)
      );
  }
  .header-tabs {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }
  .dom {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
  }
  .sources {
    grid-column: 2 / span 1;
    grid-row: 1 / span 5;
  }

  .editor.results {
    grid-column: 3 / span 1;
    grid-row: 3 / span 1;
  }

  .errors {
    grid-column: 3 / span 1;
    grid-row: 3 / span 1;
    margin-top: var(--console-min-height);
    overflow: auto;
    padding: 10px;
  }
}

.page-header {
  height: 40px;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end !important;
}

.page-title {
  margin-right: auto !important;
}

.page-title .subtitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}

.octicon {
  display: inline-block;
  vertical-align: text-top !important;
  fill: currentColor;
}

.nav-link {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
.view-gituhb {
  display: inline !important;
}

.editor {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 50%;
  position: relative;
}

.CodeMirror {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 1.6;
}

.page-body {
  padding: var(--gutter) 0;
  height: 100%;
}

[data-fixed-header-link-nav] {
  font-weight: normal;
}

.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  font-size: 24px;
  margin: -6px 2px 0 -4px;
}

.headerWrapper.wrapper > header {
  justify-content: space-between;
}

@media only screen and (any-pointer: coarse) and (max-width: 767px) {
  .fixedHeaderContainer {
    display: none;
  }
  .navPusher.navPusher {
    padding-top: 0;
  }
}

.fixedHeaderContainer > .headerWrapper.wrapper {
  max-width: 100vw;
  margin: 0;
}

#auth-section {
  margin: 0;
  flex: 0 0 120px;
  display: flex;
  height: 34px;
  white-space: nowrap;
  justify-content: flex-end;
  z-index: 10001;
  margin-right: -12px;
}

#dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  opacity: 0;
  cursor: row-resize;
  background-color: #000;
  display: none;
}

.fixedHeaderContainer {
  top: 0;
  z-index: 99999;
}
